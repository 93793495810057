<template>
    <div class="container text-center white">
        <div class="plug-content py-3">
            <div class="d-flex gap-3 mb-5">
                <img src="@/assets/img/kws-logo.svg" alt="KWS"/>
                <img src="@/assets/img/platforma-logo.svg" class="ms-3s" alt="Platforma dla Rolników"/>
            </div>
            <div class="mt-5">
                <h1 class="mb-4">Już niedługo,<br/> zaraz zaczynamy!</h1>
                <div class="f24">Platforma rusza 1 sierpnia – nie może Ciebie zabraknąć!</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PlugPage"
}
</script>

<style lang="scss">
#app {
    &.plug {
        background-image: linear-gradient(#00000099, #000000CC), url("@/assets/img/top-section-bg.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 0;

        .plug-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 100vh;
        }
    }
}
</style>
