<template>
    <div class="footer">
        <div class="container">
            <div class="footer-content">
                <div class="ms-lg-5 d-sm-flex">
                    <div>
                        <div class="f24 fw300">Masz pytania? Napisz do nas!</div>
                        <a :href="`mailto:${email}`">{{ email }}</a>
                    </div>
                    <div class="border-start d-none d-sm-block ms-sm-4 ps-sm-4">&nbsp;</div>
                    <div class="mt-3 mt-sm-0">
                        <span class="f24 fw300">Infolinia:</span> <a :href="`tel:+48${phone}`">{{ phone }}</a>
                        <div class="f14">Czynna od pn. do pt. w godz. 9:00&nbsp;-&nbsp;17:00</div>
                    </div>
                </div>
                <div class="footer-links f14">
                    <a href="/assets/frontend/pdf/regulamin.pdf" target="_blank">
                        Regulamin strony
                    </a>
                    <a href="/assets/frontend/pdf/polityka_prywatnosci.pdf" target="_blank">
                        Polityka prywatności
                    </a>
                    <a href="/assets/frontend/pdf/cookies.pdf" target="_blank">
                        Polityka cookies
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FooterComponent",
    data() {
        return {
            email: 'kontakt@polepromocji.pl',
            phone: '801800003'
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables";

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;

    &-content {
        border-top: 2px solid $gray;
        padding: 1rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.5rem;
    }

    &-links {
        display: flex;
        gap: 1rem 2rem;
        flex-wrap: wrap;
        justify-content: center;

        a {
            color: $black;
            font-weight: normal;
        }
    }

    @media (max-width: 991px) {
        &-content {
            text-align: center;
            flex-direction: column;
        }
    }
}
</style>

<style lang="scss">
#app {
    padding-bottom: 97px;

    @media (max-width: 991px) {
        padding-bottom: 142px;
    }

    @media (max-width: 375px) {
        padding-bottom: 190px;
    }
}
</style>
