<template>
  <transition name="fade">
    <div v-if="show" class="cookies">
      Używamy plików cookies (tzw. „ciasteczka”) w&nbsp;celu optymalizacji korzystania ze stron internetowych,
      tworzenia statystyk. Możesz wyłączyć ten mechanizm w&nbsp;dowolnym momencie w&nbsp;ustawieniach
      przeglądarki.
      <button type="button" @click="hide">Zamknij</button>
    </div>
  </transition>
</template>

<script>
export default {
  name: "CookieComponent",
  data() {
    return {
      show: false,
      name: 'kws_cookies',
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!localStorage.getItem(this.name)) {
        this.show = true;
      }
    },
    hide() {
      localStorage.setItem(this.name, true);
      this.show = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

$radius: 0;
$main: #ff6c00;
$background: #fff;
$color: #ff6c00;
$colorBtn: #fff;

.cookies {
  display: block;
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 400px;
  max-width: calc(100% - 20px);
  padding: 20px;
  background: #fff;
  font-size: 12px;
  line-height: 18px;
  color: $color;
  text-align: center;
  z-index: 100;
  border-radius: $radius + px;
  box-shadow: 0 6px 12px 0 rgba(0,0,0,.1);

  button {
    display: block;
    padding: 5px 15px 5px 15px;
    margin: 10px auto 0 auto;
    font-size: 11px;
    font-weight: bold;
    cursor: pointer;
    color: $colorBtn;
    background-color: $main;
    background-clip: padding-box;
    text-transform: uppercase;
    border-radius: 0;
    border: none;
    transition: background-color 0.25s;

    &:hover {
      background-color: darken($main, 10%);
    }
  }
}

@supports (-moz-appearance:none) {
  .cookies {
    background: rgba(255, 255, 255, .9);
  }
}

</style>
