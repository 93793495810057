import router from "@/router";

const emptyUser = {};

const user = {
    state: {
        isLoggedIn: !!sessionStorage.getItem('access_token'),
        user: emptyUser,
    },
    mutations: {
        setLoggedIn: function (state, payload) {
            state.isLoggedIn = payload;
        },
        userUpdate: function (state, payload) {
            state.user = {...state.user, ...payload};
        },
        emptyUser: function (state) {
            state.user = emptyUser;
        }
    },
    actions: {
        login: function ({commit}, payload) {
            return new Promise((resolve, reject) => {
                window.api.post('/login', payload)
                    .then((response) => {
                        sessionStorage.setItem('access_token', response.data.data.token);
                        commit('setLoggedIn', true);
                        router.replace( {name: 'home'});
                        resolve(response);
                    })
                    .catch((e) => {
                        if (e.response.status === 401) {
                            if (sessionStorage.getItem('access_token')) {
                                sessionStorage.removeItem('access_token')
                            }
                        }
                        reject(e);
                    })
            })
        },
        logout: function ({commit}) {
            sessionStorage.removeItem('access_token');
            commit('setLoggedIn', false);
            commit('emptyUser');
            delete window.api.defaults.headers.common['Authorization'];
            if (router.history.current.name !== 'home') {
                router.replace({name: 'home'});
            }
        },
        getUser: async function ({commit}) {
            await window.api.get('me')
                .then(response => {
                    commit('userUpdate', response.data.data);
                })
                .catch(() => {})
        },
    },
    getters: {
        isLoggedIn: function (state) {
            return !!state.isLoggedIn;
        },
        user: function (state) {
            return state.user;
        },
        hasMyKws: function (state) {
            return !!state.user.my_kws;
        }
    }
}

export default user;
