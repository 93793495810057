const defaultPayload = {
    size: 'lg',
    maxContentWidth: null,
    centered: true,
    title: null,
    body: null,
}

const popup = {
    state: {
        visible: false,
        payload: defaultPayload,
    },

    mutations: {
        showPopup: function (state, payload) {
            setTimeout(() => {
                state.visible = true;
                state.payload = {...state.payload, ...payload};
                document.body.classList.add('overflow-hidden');
            }, 5);
        },
        hidePopup: function (state) {
            state.visible = false;
            state.payload = defaultPayload;
            document.body.classList.remove('overflow-hidden');
        }
    },
}

export default popup;
