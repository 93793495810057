import Vue from 'vue'
import App from './App.vue'
import VueGtag from 'vue-gtag';
import api from './helpers/api';
import router from '@/router';
import store from '@/store';
import '@/helpers/filters';

window.api = api;

import VueDOMPurifyHTML from 'vue-dompurify-html'
Vue.use(VueDOMPurifyHTML, {
  default: {
    ADD_ATTR: ['target']
  }
});

import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import '@/assets/sass/_dialog.scss';
Vue.use(VuejsDialog)

const moment = require('moment');
require('moment/locale/pl');
Vue.use(require('vue-moment'), {moment});
window.moment = moment;

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: {id: 'G-9PYWV0HE7V'}
}, router);

new Vue({
  router,
  store: store,
  render: h => h(App),
}).$mount('#app')
