import Vue from 'vue'

Vue.filter('money', (value, decimals = 2, fullCurrency = false) => {
    value = parseFloat(value);

    if (isNaN(value)) {
        value = 0;
    }

    return value.toFixed(decimals).replace('.', ',') + '\xa0' + (fullCurrency ? 'złotych' : 'zł');
})

Vue.filter('actionStatus', (status) => {
    switch(status) {
        case 'in_progress':
            return 'Aktualnie trwa';
        case 'ending':
            return 'Kończy się za 3 dni!'
        default:
            return 'Zakończona'
    }
})
