<template>
    <div class="navigation">
        <div class="container">
            <div class="navigation-logos">
                <img src="@/assets/img/kws-logo.svg" alt="KWS" />
                <img
                    src="@/assets/img/platforma-logo.svg"
                    alt="Platforma dla rolników"
                />
            </div>

            <div>
                <div
                    class="navigation-menu__toggler"
                    :class="{ active: showMenu }"
                    @click="showMenu = !showMenu"
                >
                    <div class="bar bar-1"></div>
                    <div class="bar bar-2"></div>
                    <div class="bar bar-3"></div>
                </div>

                <div class="navigation-menu" :class="{ open: showMenu }">
                    <router-link
                        :to="{ name: 'home' }"
                        class="navigation-menu__item"
                        exact
                    >
                        Start
                    </router-link>

                    <router-link
                        :to="{ name: 'home', hash: '#promocje' }"
                        class="navigation-menu__item"
                    >
                        Promocje
                    </router-link>
                    <router-link
                        :to="{ name: 'home', hash: '#kws-pole' }"
                        class="navigation-menu__item"
                    >
                        KWS&nbsp;Pole<sup>+</sup>
                    </router-link>
                    <router-link
                        :to="{ name: 'contact' }"
                        class="navigation-menu__item"
                    >
                        Kontakt
                    </router-link>
                    <template v-if="isLoggedIn">
                        <router-link
                            :to="{ name: 'myData' }"
                            class="navigation-menu__item has-submenu"
                        >
                            Moje konto
                            <div class="navigation-submenu">
                                <router-link
                                    :to="{ name: 'myData' }"
                                    class="navigation-submenu__item"
                                >
                                    Moje dane
                                </router-link>
                                <router-link
                                    :to="{ name: 'myInvoices' }"
                                    class="navigation-submenu__item"
                                >
                                    Moje faktury
                                </router-link>
                                <router-link
                                    :to="{ name: 'promotionsHistory' }"
                                    class="navigation-submenu__item"
                                >
                                    Historia promocji
                                </router-link>
                                <router-link
                                    :to="{ name: 'changePassword' }"
                                    class="navigation-submenu__item"
                                >
                                    Zmień hasło
                                </router-link>
                            </div>
                        </router-link>
                        <a
                            href="/logout"
                            @click.prevent="logout"
                            class="navigation-menu__item"
                        >
                            Wyloguj
                        </a>
                    </template>
                    <template v-else>
                        <router-link
                            :to="{ name: 'register' }"
                            class="navigation-menu__item"
                        >
                            Załóż konto
                        </router-link>
                        <router-link
                            :to="{ name: 'login' }"
                            class="navigation-menu__item"
                        >
                            Zaloguj się
                        </router-link>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NavigationComponent",
    data() {
        return {
            showMenu: false,
        };
    },
    computed: {
        isLoggedIn: function () {
            return this.$store.getters.isLoggedIn;
        },
        isHomePage: function () {
            return this.$route.name === "home";
        },
    },
    methods: {
        logout: function () {
            this.$store.dispatch("logout");
        },
    },
    watch: {
        "$route.name": function () {
            this.showMenu = false;
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables";

.navigation {
    background-color: $white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    @extend .f20;
    font-weight: bold;
    position: relative;
    z-index: 1;

    a {
        text-transform: uppercase;
        text-decoration: none;
        color: $black;

        &:hover {
            color: $orange;
        }
    }

    .container {
        display: flex;
        justify-content: space-between;
    }

    &-menu {
        &__toggler {
            display: none;
            cursor: pointer;
            padding: 1.625rem 0;

            .bar {
                width: 40px;
                height: 4px;
                background-color: $black;
                transition: 0.2s;
                margin: 8px 0;
            }

            &.active {
                .bar-1 {
                    transform: translateY(12px) rotate(45deg);
                }

                .bar-3 {
                    transform: translateY(-12px) rotate(-45deg);
                }

                .bar-2 {
                    opacity: 0;
                }
            }
        }

        &__item {
            display: inline-block;
            padding: 47.5px 0;
            position: relative;
            margin-left: 2rem;
            transition: 0.2s;

            &.router-link-active {
                color: $green;
            }

            sup {
                right: 0;
            }

            &:after {
                content: "";
                position: absolute;
                width: 0;
                height: 6px;
                background-color: $orange;
                bottom: 0;
                left: 0;
                transition: inherit;
            }

            &:hover {
                &:not(.has-submenu):after {
                    width: 100%;
                }
            }

            &.has-submenu {
                padding-right: 1.5rem;

                &:before {
                    content: "";
                    position: absolute;
                    width: 14px;
                    height: 9px;
                    right: 0;
                    top: 45%;
                    background: url("@/assets/img/nav-chevron-green.svg") center
                        no-repeat;
                    filter: brightness(0);
                    transition: inherit;
                }

                &.router-link-active {
                    &:before {
                        filter: none;
                    }
                }

                &:hover {
                    &:before {
                        background-image: url("@/assets/img/nav-chevron-orange.svg");
                        filter: none;
                    }

                    .navigation-submenu {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }

    &-submenu {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        transition: inherit;
        top: 100%;

        &__item {
            display: block;
            margin-top: 4px;
            padding: 1rem;
            background-color: $white;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            white-space: nowrap;

            &.router-link-exact-active {
                color: $green;
            }
        }
    }
    @media (max-width: 1399px) {
        &-logos {
            img {
                max-width: 95px;
            }
        }

        &-menu__item {
            font-size: 1.125rem;
            padding: 36px 0;
        }
    }

    @media (max-width: 1199px) {
        &-menu__item {
            margin-left: 1rem;
        }
    }

    @media (max-width: 991px) {
        &-logos {
            img {
                max-width: 6rem;
            }
        }

        &-menu {
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            background-color: $white;
            transition: 0.2s;
            z-index: 10;
            opacity: 0;
            visibility: hidden;
            transform: scale(5);
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            max-height: calc(100vh - 100px);
            overflow-y: auto;

            &.open {
                opacity: 1;
                visibility: visible;
                transform: scale(1);

                .navigation-submenu {
                    opacity: 1;
                    visibility: visible;
                }
            }

            &__item {
                display: block;
                text-align: center;
                margin: 0;
                padding: 1.5rem;

                &:after {
                    left: 50%;
                    transform: translateX(-50%);
                }

                &.has-submenu {
                    &:before {
                        content: none;
                        right: 20px;
                    }
                }
            }

            &__toggler {
                display: block;
            }
        }

        &-submenu {
            position: relative;

            &__item {
                box-shadow: none;
                padding-bottom: 0;
            }
        }
    }

    @media (max-width: 767px) {
        &-menu {
            &__toggler {
                padding: 1.4rem 0;
            }
        }
    }
}
</style>
