<template>
    <div class="popup fade" :class="{show: visible}">
        <div class="popup-backdrop fade"></div>
        <div class="popup-dialog" :class="[{'popup-dialog-centered': centered}]">
            <div class="popup-content" :class="`popup-content-${size}`">
                <div class="popup-header">
                    <button class="close" @click="close"></button>
                    <h2 class="text-start orange mb-0">{{ title }}</h2>
                </div>
                <div class="popup-body" :class="{'pt-4': !!title}">
                    <div v-if="typeof body === 'string'" v-dompurify-html="body"/>
                    <component v-else :is="body" v-bind="props"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "PopupComponent",
    computed: {
        ...mapState({
            visible: state => state.popup.visible,
            size: state => state.popup.payload.size,
            centered: state => state.popup.payload.centered,
            title: state => state.popup.payload.title,
            body: state => state.popup.payload.body,
            props: state => state.popup.payload.props,
        })
    },
    methods: {
        close: function () {
            if (['login', 'register', 'forgotPassword', 'resetPassword', 'verify'].includes(this.$route.name)) {
                this.$router.replace({name: 'home'})
            }
            this.$store.commit('hidePopup');
        }
    },
    mounted() {
        this.$el.addEventListener('click', (e) => {
            ['popup-dialog', 'popup-backdrop'].some(c => {
                if (e.target.classList.value.includes(c)) {
                    this.close()
                }
            })
        })

        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                this.close()
            }
        })
    }
}
</script>

<style scoped lang="scss">
.popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;

    &.show {
        opacity: 1;
        visibility: visible;

        .popup {
            &-backdrop {
                opacity: 0.96;
            }

            &-dialog {
                transform: none;
            }
        }
    }

    &-backdrop {
        width: 100vw;
        height: 100vh;
        background-color: #00413C;
        opacity: 0;
        position: fixed;
    }

    &-dialog {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        position: relative;
        width: auto;
        padding: 0.75rem;
        transform: translateY(-50px);
        transition: transform .2s;

        &-centered {
            min-height: 100%;
        }
    }

    &-content {
        background-color: #FFF;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;

        &-sm {
            max-width: 480px;
        }

        &-md {
            max-width: 594px;
        }

        &-lg {
            max-width: 990px;
        }

        &-xl {
            max-width: 1140px;
        }
    }

    &-header {
        padding: 1.5rem 1.5rem 0;
        text-align: right;

        .close {
            padding: 0;
            width: 2rem;
            height: 2rem;
            background: url("@/assets/img/close.svg") center no-repeat;
            background-size: cover;
            border: none;
        }
    }

    &-body {
        position: relative;
        flex: 1 1 auto;
        padding: 0 1.5rem 1.5rem;
    }

    @media (max-width: 767px) {
        &-header {
            padding: 1rem 1rem 0;
        }

        &-body {
            padding: 1rem;
        }
    }
}

.fade {
    transition: .15s;
}
</style>
