import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "@/router/routes";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: "/",
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({ selector: to.hash, behavior: "smooth" });
                }, 300);
            });
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
    routes,
});

router.beforeEach(async function (to, from, next) {
    if (to.meta.requiresAuth) {
        store.getters.isLoggedIn ? next() : next({ name: "login" });
    } else if (to.fullPath === "/promocje") {
        next("/#promocje");
    } else {
        next();
    }

    if (store.getters.isLoggedIn) {
        await store.dispatch("getUser");
    }
});

export default router;
