const emptyForm = {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    name_farm: '',
    street: '',
    building_number: '',
    flat_number: '',
    city: '',
    post_code: '',
    province: '',
    county: '',
    email_notification: false,
    phone_notification: false,
    post_notification: false,
    data_farm: [],
    password: '',
    password_confirmation: '',
    agreement_1: false,
    agreement_2: false,
    agreement_3: false,
}

const register = {
    state: {
        form: {...emptyForm},
        response: null,
    },
    mutations: {
        updateFarmData: function (state, payload) {
            state.form.data_farm = payload;
        },
        emptyForm: function (state) {
            state.form = {...emptyForm};
        },
        setResponse: function (state, payload) {
            state.response = payload
        }
    },
    actions: {
        submitForm: async function ({state, commit}) {
            return new Promise((resolve, reject) => {
                window.api.post('register', state.form)
                    .then(response => {
                        commit('setResponse', {
                            success: true,
                            data: response.data.data
                        });
                        commit('emptyForm');
                        resolve(response);
                    })
                    .catch(error => {
                        commit('setResponse', {
                            success: false,
                            data: error.response.data
                        });
                        reject(error);
                    });
            })
        }
    },
    getters: {
        registerForm: (state) => {
            return state.form;
        },
        registerResponse: (state) => {
            return state.response;
        },
        stepErrors: () => (response) => {
            try {
                return response.data.errors;
            } catch {
                return {}
            }
        },
        registerErrors: (state) => (field = null) => {
            try {
                let errors = state.response.data.errors;
                return field ? errors[field] : errors;
            } catch {
                return field ? [] : {}
            }
        },
        farmData: (state) => {
            return state.form.data_farm;
        }
    }
}

export default register;
