import Vue from 'vue'
import Vuex from 'vuex'

import user from './states/user'
import popup from './states/popup'
import register from './states/register'

Vue.use(Vuex)

const index = new Vuex.Store({
  modules: {
    user: user,
    popup: popup,
    register: register,
  }
})

export default index
