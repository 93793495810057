<template>
    <div id="app" :class="{'plug': showPlug}">
        <div v-if="!systemStarted && !showPlug" class="loader">
            <loader-component size="lg"/>
        </div>
        <template v-else-if="systemStarted">
            <navigation-component/>
            <transition name="fade" mode="out-in">
                <router-view/>
            </transition>
            <footer-component/>
            <popup-component/>
        </template>
        <plug-page v-else/>
        <cookie-component/>
    </div>
</template>

<script>
import axios from "axios";
import NavigationComponent from "@/components/NavigationComponent";
import FooterComponent from "@/components/FooterComponent";
import PopupComponent from "@/components/PopupComponent";
import PlugPage from "@/pages/PlugPage";
import LoaderComponent from "@/components/LoaderComponent";
import CookieComponent from "@/components/CookieComponent";

export default {
    name: 'App',
    components: {
        CookieComponent,
        LoaderComponent,
        PlugPage,
        PopupComponent,
        FooterComponent,
        NavigationComponent,
    },
    data() {
        return {
            systemStarted: null,
        }
    },
    computed: {
        showPlug: function () {
            return this.systemStarted === false;
        }
    },
    async beforeCreate() {
        await axios.get('system-started')
            .then(response => {
                this.systemStarted = response.data;
            })
            .catch(() => {
                this.systemStarted = false;
            });
    }
}
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
@import "@/assets/sass/fonts";
@import "@/assets/sass/variables";
@import "@/assets/sass/form";

html {
    font-size: 16px;

    @media (max-width: 767px) {
        font-size: 14px;
    }
}

body {
    margin: 0;
    overflow-x: hidden;
    @extend .f18;
    line-height: 1.25;
}

#app {
    font-family: "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    overflow: hidden;
    min-height: 100vh;
}

a, .link {
    transition: .2s;
    color: $orange;
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;

    &.download {
        padding-left: 1.5rem;
        position: relative;
        display: inline-block;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            width: 1.25em;
            height: 1.25em;
            background: url("@/assets/img/download-icon.svg") center no-repeat;
            background-size: 100%;
        }
    }
}

h1 {
    @extend .f80;
    font-weight: 300;
    line-height: 1.1;
}

h2 {
    @extend .f56;
    font-weight: 300;
    line-height: 1.2;
}

h3 {
    @extend .f48;
    font-weight: 300;
    line-height: 1.17;
}

h4 {
    @extend .f32;
    font-weight: 300;
    line-height: 1.25;
}

h1, h2, h4 {
    .plus {
        position: absolute;
        transform: translate(-33%, -16%);
        width: 0.6em;
        height: 0.6em;
        background: url("@/assets/img/plus.svg") center no-repeat;
        background-size: 100%;

        &.middle {
            transform: translate(10%, 50%);
        }
    }
}

b, strong {
    font-weight: bold;
}

sup {
    font-size: inherit;
    right: 0.15em;
    top: -0.4em;
}

table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0.25rem;
    @extend .f16;

    th, td {
        padding: 0.7rem 1rem;
    }

    th {
        color: $white;
        background-color: $orange;
    }

    td {
        background-color: #F1F3F5;
    }
}

hr {
    margin: 0;
    height: 2px !important;
    background-color: $gray;
    opacity: 1;
}

.status-label {
    position: absolute;
    top: 1.5rem;
    left: 0;
    padding: .5rem 1rem .5rem 1.5rem;
    color: #5F696E;
    background-color: #C8D0D6;
    @extend .f16;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 4px 3px 16px rgba(75, 52, 35, 0.24);

    &.in_progress {
        color: $orange;
        background-color: $white;
    }

    &.end_is_near {
        color: $white;
        background-color: #BE5F46;
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .2s ease-out;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>

<style scoped>
.loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>
