export const routes = [
    {
        path: "",
        name: "home",
        component: () => import("@/pages/HomePage"),
    },
    {
        path: "/kws-pole+/:id",
        name: "kwsPole",
        component: () => import("@/pages/KwsPolePage"),
    },

    {
        path: "/promocje/:id",
        name: "promotions",
        component: () => import("@/pages/PromotionsPage"),
    },
    {
        path: "/kontakt",
        name: "contact",
        component: () => import("@/pages/ContactPage"),
    },
    {
        path: "/moje-konto",
        component: () => import("@/pages/AccountPage"),
        children: [
            {
                path: "",
                name: "myData",
                meta: { requiresAuth: true },
                component: () => import("@/pages/account/MyDataPage"),
            },
            {
                path: "zmien-haslo",
                name: "changePassword",
                meta: { requiresAuth: true },
                component: () => import("@/pages/account/ChangePasswordPage"),
            },
            {
                path: "historia-promocji",
                name: "promotionsHistory",
                meta: { requiresAuth: true },
                component: () => import("@/pages/account/PromotionsPage"),
            },
            {
                path: "moje-faktury",
                name: "myInvoices",
                meta: { requiresAuth: true },
                component: () => import("@/pages/account/InvoicesPage"),
            },
        ],
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/pages/HomePage"),
    },
    {
        path: "/register",
        name: "register",
        component: () => import("@/pages/HomePage"),
    },
    {
        path: "/verify/:id/:token",
        name: "verify",
        component: () => import("@/pages/HomePage"),
    },
    {
        path: "/forgot-password",
        name: "forgotPassword",
        component: () => import("@/pages/HomePage"),
    },
    {
        path: "/reset-password/:token?",
        name: "resetPassword",
        component: () => import("@/pages/HomePage"),
    },
    {
        path: "/l098098098/:token?",
        name: "autologin",
        component: () => import("@/pages/HomePage"),
    },
    {
        path: "*",
        name: "notFound",
        component: () => import("@/pages/NotFoundPage"),
    },
];
